import React from "react"
import { Button, Image, Input, Text, VStack } from "@chakra-ui/react"
import { useRealmApp } from "../../context/RealmApp"

export default function LogInForm() {
  const { logIn, loading } = useRealmApp() as any

  function handleSubmit(event: any) {
    event.preventDefault()
    const data = new FormData(event.currentTarget) as any
    const { email, password } = Object.fromEntries(data)

    logIn(email, password).catch(error => {
      console.error("this is the error: ", error)
    })
  }

  return (
    <VStack as="form" method="post" onSubmit={handleSubmit}>
      <VStack
        as="fieldset"
        spacing={4}
        disabled={loading}
        aria-busy={loading}
        align="center"
        minW="300px"
      >
        <Input
          id="log-in-email"
          type="email"
          name="email"
          autoComplete="username"
          required
          variant="flushed"
          placeholder="Email"
        />

        <Input
          id="log-in-password"
          type="password"
          name="password"
          autoComplete="current-password"
          required
          variant="flushed"
          placeholder="Password"
        />

        <Button
          type="submit"
          isDisabled={loading}
          bg="red.full"
          w="full"
          _loading={{
            bg: "orange.full",
            opacity: 1,
          }}
        >
          {loading ? "Logging in..." : "Log in"}
        </Button>
      </VStack>
    </VStack>
  )
}
