import React, { useEffect, ReactNode, useContext } from "react"
import { navigate } from "gatsby"

import { AppContext } from "../../context/ContextProvider"
import Section from "gatsby-theme-wild-child/src/components/Section"
import { Center, Spinner } from "@chakra-ui/react"
import { useRealmApp } from "../../context/RealmApp"

interface Props {
  children: ReactNode
  redirect?: string
  loadingElement?: any
}

export default function UnAuthContent(props: Props) {
  const { loading, currentUser } = useRealmApp() as any
  const { children, redirect = "/" } = props

  // Navigate authenticated users to Redirect page.
  useEffect(() => {
    if (currentUser?._profile?.data?.email && !loading) {
      navigate(redirect)
    }
  }, [loading, navigate])

  return !currentUser?._profile?.data?.email ? (
    <>{children}</>
  ) : (
    <Section h="100vh" bg="dark.full" id="loading">
      <Center layerStyle="fillSpace">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="blue.900"
          color="orange.full"
          size="xl"
        />
      </Center>
    </Section>
  )
}
