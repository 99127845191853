import { Image, VStack } from "@chakra-ui/react"
import Section from "gatsby-theme-wild-child/src/components/Section"
import React from "react"
import LogInForm from "../components/auth/LogInForm"

function Login() {
  return (
    <Section bg="white" h="100vh">
      <VStack layerStyle="fillSpace" justify="center">
        <Image
          src="https://res.cloudinary.com/wild-creative/image/upload/v1673849636/virtuzone-logo-1_fchemo.svg"
          w="300px"
        />
        <LogInForm  />
      </VStack>
    </Section>
  )
}

export default Login
