import React from "react"
import Login from "../gatsby-theme-wild-child/components/Login"
import UnAuthContent from "../gatsby-theme-wild-child/components/auth/UnAuthContent"

function login() {
  return (
    <UnAuthContent>
      <Login />
    </UnAuthContent>
  )
}

export default login

export const Head = () => <title>TaxGPT by Virtuzone | Signin</title>
